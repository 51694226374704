import { reactive } from "vue";
import mitt from "mitt";
const emitter = mitt();

export const eventBus = reactive({
    contextualPaywall: {
        context: null,
        contextualProducts: null,
        partner: null,
        contextualProduct: null,
        adyenSession: null,
        agbVersionByDomain: null,
    },
    store: {
        rewards: {},
        promotion: {},
    },
    flash: {
        previousBanner: null,
        banner: null,
        partner: null,
        bannerData: null,
        coins: null,
        verificationId: null,
        verificationComplete: null,
        toast: null,
        inboxTrigger: null,
    },
    placeHolderMessage: null,
    clearFlash() {
        this.flash.banner = null;
        this.flash.partner = null;
        this.flash.bannerData = null;
        this.flash.toast = null;
    },
    clearMessage() {
        this.placeHolderMessage = null;
    },
    clearContext() {
        this.contextualPaywall.contextualProduct = null;
        this.contextualPaywall.contextualProducts = null;
        this.contextualPaywall.adyenSession = null;
        this.contextualPaywall.agbVersionByDomain = null;
    },
    pushMessage(message, context, data) {
        const messageMap = {
            question_game_asked: {
                message: "messenger_question_game_asked",
                message_type: "question_game_asked",
                data: data,
            },
            question_game_answered: {
                message: "messenger_question_game_answered",
                message_type: "question_game_answered",
                data: data,
            },
            send_smile: {
                message: "messenger_smiley",
                message_type: "smiley",
                data: "[]",
            },
            share_my_photo: {
                message: "messenger_share_my_photo",
                message_type: "share_my_photo",
                data: "[]",
            },
            exclusive_message: {
                message: "messenger_sent_exclusive_message",
                message_type: "sent_exclusive_message",
                data: btoa(data?.content || ""),
            },
            compliment: {
                message: "messenger_made_compliment",
                message_type: "made_compliment",
                data: "[]",
            },
            send_rose: {
                message: "messenger_sent_rose",
                message_type: "sent_rose",
                data: "[]",
            },
        };

        const mappedMessage = messageMap[context];
        const newMessage = {
            message: mappedMessage.message,
            context: mappedMessage.message_type,
            data: mappedMessage.data || data,
        };

        emitter.emit("newPlaceholder", newMessage);
    },
    switchConversation(partner) {
        emitter.emit("switchConversation", partner);
    },
    updateSharePhoto(data) {
        emitter.emit("updatedSharePhoto", data);
    },
    selectSpecialMessage(type) {
        emitter.emit("selectedSpecialMessage", type);
    },
    deselectSpecialMessage() {
        emitter.emit("deselectedSpecialMessage");
    },
    on(event, callback) {
        emitter.on(event, callback);
    },
    off(event, callback) {
        emitter.off(event, callback);
    },
});
